import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import Text from "../components/atoms/Text/Text";

const ImpressPage = () => {
    const {t} = useTranslation();

    return (
        <>
            <FullWidthContainer>
                <h1>{t('contact:mainHeadline')}</h1>
            </FullWidthContainer>
            <FullWidthContainer>
                <h2 className="margin-top-0">{t('contact:sectionContactUs.headline')}</h2>
                <h3>{t('contact:sectionContactUs.subHeadlineAddress')}</h3>
                <p><Text text={t('contact:sectionContactUs.address')} /></p>
                <h3>{t('contact:sectionContactUs.subHeadlineContact')}</h3>
                <p><Text text={t('contact:sectionContactUs.contactInfo')} /></p>
            </FullWidthContainer>
            <FullWidthContainer>
                <h2>{t('contact:sectionSideEffects.headline')}</h2>
                <p><Text text={t('contact:sectionSideEffects:contactInfo')} /></p>
            </FullWidthContainer>
        </>
    )
}

export default ImpressPage;

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "contact"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
	}
`;
