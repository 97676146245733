import React from "react";
import {Trans} from "react-i18next";

const Text = ({text} : {text: string}) => (
    <Trans
      i18nKey={text}
      components={{
        sup: <sup />,
        strong: <strong />,
        i: <i />,
        br: <br />,
        lt: "<",
        a: <a />,
        u: <u />
      }}
    />
);

export default Text;